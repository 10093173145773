import { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { ON_BOARDING_DEFAULT_LOCATION, onBoardingUpdateAction } from "../../reducers/onboarding";
import './OnBoarding.scss';
import { OnBoardingBirthdate } from "./components/OnBoardingBirthdate";
import { OnBoardingLocation } from "./components/OnBoardingLocation";
import { OnBoardingName } from "./components/OnBoardingName";
import { OnBoardingOtherType } from "./components/OnBoardingOtherType";
import { OnBoardingSexSelect } from "./components/OnBoardingSexSelect";
import { OnBoardingAbout } from "./components/OnBoardingAbout";
import { OnBoardingType } from "./components/OnBoardingType";
import { OnBoardingPublicPhotos } from './components/OnBoardingPublicPhotos';
import { OnBoardingAvatar } from "./components/OnBoardingAvatar";
import { OnBoardingPrivatePhotos } from "./components/OnBoardingPrivatePhotos";
import { PROFILE_TYPE } from "../../types/models/profile";
import { ONBOARDING_STEP } from '../../types/enum';

export function OnBoarding({profileType}: {profileType: PROFILE_TYPE | false}) {

  const dispatch = useDispatch()

  const user = useSelector(state => state.user.user)
  const step = useSelector(state => state.onBoarding.step)

  useEffect(() => {

    if (user !== null) {
      dispatch(onBoardingUpdateAction({
        values: {
          sex: user.sex,
          location: user.userLocation.location ? user.userLocation : ON_BOARDING_DEFAULT_LOCATION,
          step: profileType ? ONBOARDING_STEP.NAME : ONBOARDING_STEP.PROFILE_TYPE,
          profileType : profileType ? profileType : null
        }
      }))
    }
  }, [])

  const stepComponents = {
    [ONBOARDING_STEP.SEX]: <OnBoardingSexSelect />,
    [ONBOARDING_STEP.LOCATION]: <OnBoardingLocation />,
    [ONBOARDING_STEP.PROFILE_TYPE]: <OnBoardingType />,
    [ONBOARDING_STEP.NAME]: <OnBoardingName profileType={profileType} />,
    [ONBOARDING_STEP.AVATAR]: <OnBoardingAvatar />,
    [ONBOARDING_STEP.PUBLIC_PHOTOS]: <OnBoardingPublicPhotos />,
    [ONBOARDING_STEP.PRIVATE_PHOTOS]: <OnBoardingPrivatePhotos />,
    [ONBOARDING_STEP.BIRTHDATE]: <OnBoardingBirthdate />,
    [ONBOARDING_STEP.ABOUT]: <OnBoardingAbout />,
    [ONBOARDING_STEP.OTHER]: <OnBoardingOtherType />,
  };

  const getBody = () => stepComponents[step as ONBOARDING_STEP] || null;

  return (
    <div className={`on-boarding container`}>
      {getBody()}
    </div>
  )

}
