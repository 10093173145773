import EditIcon from "../../../icons/EditIcon";
import {NavLink} from "react-router-dom";
import {PROFILE_TYPE} from "../../../types/models/profile";
import { getProfileColor } from '../../../utils';
import { ROUTES } from "../../../types/enum";

const profileEditRoutes: Record<PROFILE_TYPE, string> = {
  friend: ROUTES.PROFILE_EDIT_FRIEND,
  flirt: ROUTES.PROFILE_EDIT_FLIRT,
  fun: ROUTES.PROFILE_EDIT_FUN,
};

export default function EditProfileLink({type}: {type: PROFILE_TYPE}) {
  return (
    <NavLink 
    to={profileEditRoutes[type]} 
    className="profile-page-edit-profile profile-text-color"
    style={{color: getProfileColor(type)}}

>
      <EditIcon/>
      Edit profile
    </NavLink>
  )
}
