import { useEffect, useState } from "react";
import "./DropDown.scss";
import { useSelector } from "react-redux";

interface IDropDownItemState {
  [key: string]: boolean;
}

interface IDropDownItem {
  itemName: string;
  itemContent: React.ReactNode;
  id: string;
}

const DropDown = ({ items }: { items: IDropDownItem[] }) => {
  const [tabsOpened, setTabsOpened] = useState<IDropDownItemState>({});

  useEffect(() => {
    if (items) {
      const initialState: IDropDownItemState = {};
      items.forEach((item) => {
        initialState[item.id] = false;
      });

      setTabsOpened(initialState);
    }
  }, [items]);

  const ontToggleDropdownItem = (itemId: string) => {
    setTabsOpened((state) => ({ ...state, [itemId]: !state[itemId] }));
  };

  return (
    <div className="dropdown">
      {items.map((item, i) => {
        return (
          <DropDownItem
            tabOpened={tabsOpened[item.id]}
            item={item}
            key={i}
            onToggleDropdownItem={ontToggleDropdownItem}
          />
        );
      })}
    </div>
  );
};

export const DropDownItem = ({
  tabOpened,
  item,
  onToggleDropdownItem,
}: {
  tabOpened: boolean;
  item: IDropDownItem;
  onToggleDropdownItem: (itemId: string) => void;
}) => {
  const activeType = useSelector((state) => state.user.activeType);

  return (
    <div
      className={`dropdown-item ${activeType} ${tabOpened ? "active" : ""}`}
      key={item.id}
    >
      <div className="dropdown-item-tab">
        <div className="dropdown-item-tab-button">
          <button
            onClick={() => {
              onToggleDropdownItem(item.id);
            }}
          >
            <div className="dropdown-item-tab-button-name">
              <span className="profile-page-info-area-title">
                {item.itemName}
              </span>
            </div>
            <img
              src={`/images/icons/common/arrow-${
                tabOpened ? "up" : "down"
              }.svg`}
              alt="Arrow down"
            />
          </button>
        </div>
      </div>
      <div className={`dropdown-item-content ${tabOpened ? "active" : ""}`}>
        {item.itemContent}
      </div>
    </div>
  );
};

export default DropDown;
