import {PhotosInput} from "../../../components/ui/PhotosInput/PhotosInput";
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {PROFILE_TYPES} from "../../../constants";
import {PHOTO_TYPE} from "../../../types/models/photo";

export function OnBoardingAvatar() {

  const avatar = useSelector(state => state.onBoarding.avatar)
  const profileType = useSelector(state => state.onBoarding.profileType)

  const profileData = profileType ? PROFILE_TYPES[profileType] : null;

  const dispatch = useDispatch()

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'name'
      }
    }))
  }

  const submit = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'publicPhotos'
      }
    }))
  }

  const changePhotos = (photos: PHOTO_TYPE[]) => {
    dispatch(onBoardingUpdateAction({
      values: {
        avatar: photos.length > 0 ? photos[0] : null
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Upload your main Profile photo</span>
      <span className="on-boarding-text">
        In order to complete your profile, you must have a profile photo. Keep in mind, no eggplants or peaches or any other type of fruit. You can save those for your private photos. But since this is your{' '}
        {profileData && (
          <span
            className="uppercase"
          >
            {profileData.label}
          </span>
        )}
        {' '}profile, those types of photos are best saved for your {profileData && <span className="uppercase">{profileData.label}</span>} profile.
      </span>
      {profileType && (
        <PhotosInput
          photos={avatar ? [avatar]: []}
          onChange={photos => {changePhotos(photos)}}
          maxLength={1}
          profileType={profileType}
          photoType={'public'}
        />
      )}
      <button
        disabled={avatar === null}
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
    </>
  )
}
