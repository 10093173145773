import React, {useEffect, useState} from 'react'
import {Button, TextInput} from "../../components";
import {useNavigate, useSearchParams} from "react-router-dom";
import {validatePassword} from "../../utils";
import {useDispatch, useSelector} from "react-redux";
import {userSignOutAction} from "../../reducers/user";
import {ResetPasswordActivation} from "./components/ResetPasswordActivation";
import {apiUserChangePassword, apiUserCheckResetCode} from "../../actions/userActions";
import LogoWithIcon from "../../icons/LogoWithIcon";
import { ROUTES } from '../../types/enum';

export function ResetPassword({auth}: {auth: boolean}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams();

  const profiles = useSelector(state => state.user.profiles)
  const token = useSelector(state => state.user.token)
  const [resetToken, setResetToken] = useState(null)

  const [submitLoading, setSubmitLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [success, setSuccess] = useState(false)
  const [loading, setLoading] = useState(!auth)

  useEffect(() => {
   checkResetCode()
  }, [])

  const checkResetCode = async () => {
    if (!auth) {
      const resetCode = searchParams.get('reset_code')
      if (resetCode) {
        const response = await apiUserCheckResetCode(resetCode)
        if (response.status) {
          setResetToken(response.token)
          setLoading(false)
        }
      }

    }
  }

  const checkSubmitButtonDisabled = () => {
    return !validatePassword(password) || password !== confirmPassword
  }

  const onSubmit = async () => {

    if ((auth && token) || (!auth && resetToken)) {
      setSubmitLoading(true)
      // @ts-ignore
      await apiUserChangePassword(auth ? token: resetToken, password)
      setSuccess(true);
      dispatch(userSignOutAction());
      navigate('/');
    }

  }

  const onBack = () => {

    if (profiles.length === 0) {
      dispatch(userSignOutAction())
    }

    navigate(ROUTES.SETTINGS)

  }

  if (success) {
    return (
      <ResetPasswordActivation/>
    )
  }

  if (loading) {
    return <div/>
  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <h3 className="sign-up-title">Change password</h3>
        <span className="sign-up-text">Please create a new password</span>
        <div className="sign-up-field">
          <TextInput
            id={'password'}
            label={'Password'}
            type={'password'}
            value={password}
            onChange={(_password) => {
              setPassword(_password)
            }}
            placeholder={'Enter your password'}
          />
        </div>
        <div className="sign-up-field">
          <TextInput
            id={'confirmPassword'}
            label={'Confirm your password'}
            type={'password'}
            value={confirmPassword}
            onChange={(_password) => {
              setConfirmPassword(_password)
            }}
            placeholder={'Confirm your password'}
          />
        </div>
        <div className="sign-up-button">
          <Button
            label={'Change Password'}
            onClick={onSubmit}
            disabled={checkSubmitButtonDisabled()}
            loading={submitLoading}
          />
        </div>
        <div className='sign-up-back'>
          <span onClick={onBack} className="sign-up-back">Back</span>
        </div>
      </div>
    </div>
  )

}
