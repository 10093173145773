import "./AsideMenu.scss";
import { userSignOutAction } from "../../reducers/user";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import useBreakpoints from "../../hooks/useBreakpoints";
import { getProfileColor } from "../../utils";
import { ABOUT_URL, BLOG_URL, CONTACT_US_URL } from "../../constants";
import { applicationToggleNavDrawer } from "../../reducers/application";
import { ROUTES } from "../../types/enum";

export function AsideMenu({}) {
  const activeType = useSelector((state) => state.user.activeType);
  const navigationDrawerOpened = useSelector((state) => state.application.navigationDrawer.isOpened);
  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);


  const dispatch = useDispatch();

  const { lgUp } = useBreakpoints();

  const closeMobileNavDrawer = () => {
    if (!lgUp && navigationDrawerOpened) {
      dispatch(applicationToggleNavDrawer());
    }
  };

  const signOut = () => {
    closeMobileNavDrawer();
    dispatch(userSignOutAction());
  };

  return (
    <div className={`left-menu ${activeType}`}>
      {isLoggedIn && (
        <div>
          <NavLink
            to={ROUTES.PROFILE}
            className="left-menu-element"
            onClick={closeMobileNavDrawer}
          >
            <img
              src="/images/icons/menu/profile.svg"
              alt=""
              className="left-menu-element-icon"
            />
            <span className="left-menu-element-label">Profile</span>
          </NavLink>
          <NavLink
            to={ROUTES.MESSAGES}
            className="left-menu-element"
            onClick={closeMobileNavDrawer}
          >
            <img
              src="/images/icons/menu/messages.svg"
              alt=""
              className="left-menu-element-icon"
            />
            <span className="left-menu-element-label">Messages</span>
          </NavLink>
          <NavLink
            to={ROUTES.NOTIFICATIONS}
            className="left-menu-element"
            onClick={closeMobileNavDrawer}
          >
            <img
              src="/images/icons/menu/notifications.svg"
              alt=""
              className="left-menu-element-icon"
            />
            <span className="left-menu-element-label">Notifications</span>
          </NavLink>
          <NavLink
            to={ROUTES.STATS}            
            className="left-menu-element"
            onClick={closeMobileNavDrawer}
          >
            <img
              src="/images/icons/menu/stats.svg"
              alt=""
              className="left-menu-element-icon"
            />
            <span className="left-menu-element-label">Stats</span>
          </NavLink>
          <NavLink
            to={ROUTES.SETTINGS}            
            className="left-menu-element"
            onClick={closeMobileNavDrawer}
          >
            <img
              src="/images/icons/menu/settings.svg"
              alt=""
              className="left-menu-element-icon"
            />
            <span className="left-menu-element-label">Settings</span>
          </NavLink>
        </div>
      )}

      <div
        className="left-menu-bottom"
        style={
          !lgUp ? (isLoggedIn ? { borderTop: `1px solid ${getProfileColor(activeType)}` } : {borderBottom: `1px solid ${getProfileColor(activeType)}`}) : {}
        }
      >
        {isLoggedIn && (
          <span
            className="left-menu-element left-menu-element-logout"
            onClick={signOut}
          >
          <img
            src="/images/icons/menu/logout.svg"
            alt=""
            className="left-menu-element-icon"
          />
          <span className="left-menu-element-label">Log Out</span>
        </span>
        )}
        {!lgUp && (
          <>
            <NavLink
              to={CONTACT_US_URL}
              className="left-menu-element"
              onClick={closeMobileNavDrawer}
            >
              <span className="left-menu-element-label">Contact Us</span>
            </NavLink>
            <NavLink
              to={BLOG_URL}
              className="left-menu-element"
              onClick={closeMobileNavDrawer}
            >
              <span className="left-menu-element-label">Blog</span>
            </NavLink>
            <NavLink
              to={ABOUT_URL}
              className="left-menu-element"
              onClick={closeMobileNavDrawer}
            >
              <span className="left-menu-element-label">About Us</span>
            </NavLink>
          </>
        )}
      </div>
    </div>
  );
}
