import {useSelector} from "react-redux";
import {getOnBoardingPercent} from "../../../reducers/onboarding";
import { PROFILE_TYPES} from "../../../constants";

export function ProgressLine() {

  const step = useSelector(state => state.onBoarding.step)
  const type = useSelector(state => state.onBoarding.profileType)
  const profileData = type?  PROFILE_TYPES[type] : null


  return (
    <>
      <div className="on-boarding-line">
        <div className="on-boarding-line-background" style={{width: `${getOnBoardingPercent(step)}%`}}/>
      </div>
      {type && step !== 'profileType' && (
        <div className="on-boarding-type-icon">
          <img
            src={profileData?.icon}
            alt=""
            className="on-boarding-type-icon-image"
          />
          <span
            style={{ color: profileData?.color }}
            className="on-boarding-type-icon-label"
          >
            {[profileData?.label]}
          </span>
        </div>
      )}
    </>
  )
}
