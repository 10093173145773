import React, {useState} from 'react'
import HeyIcon from "../../../icons/interactions/HeyIcon";
import FavoriteIcon from "../../../icons/interactions/FavoriteIcon";
import VisibilityIcon from "../../../icons/interactions/VisibilityIcon";
import NoteIcon from "../../../icons/interactions/NoteIcon";
import BlockIcon from "../../../icons/interactions/BlockIcon";
import {useDispatch, useSelector} from "react-redux";
import {apiChatCreate} from "../../../actions/chatActions";
import {chatCreateAction, chatSetActiveAction} from "../../../reducers/chat";
import {useNavigate} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import {Modal} from "../../../components/ui/Modal/Modal";
import {VisibilityModal} from "../../../components/VisibilityModal/VisibilityModal";
import {USER_PROFILE} from "../../../types/models/profile";
import {NoteModal} from "../../../components/NoteModal/NoteModal";
import {BlockModal} from "../../../components/BlockModal/BlockModal";
import {apiProfileFavourite, apiProfileFlex} from "../../../actions/profileActions";
import NoteCircleIcon from "./icons/NoteCircleIcon";
import { ROUTES } from '../../../types/enum';

type MoreInteractionProps = {
  profile: USER_PROFILE
  openNoteModal: () => void
  onChangeBlock: (block: 0 | 1) => void
}

function MoreInteraction({profile, onChangeBlock, openNoteModal}: MoreInteractionProps) {

  const [visibilityModalOpen, openVisibilityModal, closeVisibilityModal] = useModal()
  const [blockModalOpen, openBlockModal, closeBlockModal] = useModal()

  const profiles = useSelector(state => state.user.profiles)

  return (
   <>
     <Modal
       open={visibilityModalOpen}
       content={<VisibilityModal profile={profile} onCancel={closeVisibilityModal}/>}
       onClose={closeVisibilityModal}
     />
     <Modal
       open={blockModalOpen}
       content={(
         <BlockModal
           onCancel={closeBlockModal}
           profile={profile}
           onChangeBlock={onChangeBlock}
         />
       )}
       onClose={closeBlockModal}
     />
     <div className="more-interaction">
       <span className="more-interaction-label profile-text-color">More interactions</span>
       <div className="more-interaction-submenu">
         {profiles.length > 1 && (
           <div className="more-interaction-submenu-element" onClick={openVisibilityModal}>
             <div className="more-interaction-submenu-element-icon">
               <VisibilityIcon/>
             </div>
             <span className="more-interaction-submenu-element-label">Visibility of profiles</span>
           </div>
         )}
         <div className="more-interaction-submenu-element"  onClick={openNoteModal}>
           <div className="more-interaction-submenu-element-icon">
             <NoteIcon/>
           </div>
           <span className="more-interaction-submenu-element-label">Make a note</span>
         </div>
         <div className="more-interaction-submenu-element" onClick={openBlockModal}>
           <div className="more-interaction-submenu-element-icon">
             <BlockIcon/>
           </div>
           <span className="more-interaction-submenu-element-label">Block this user</span>
         </div>
       </div>
     </div>
   </>
  )

}

function ChatButton({profileId}: {profileId: number}) {

  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const token = useSelector(state => state.user.token)
  const chats = useSelector(state => state.chat.chats)
  const chat = chats.find(_chat => _chat.profile.id === profileId)

  const dispatch = useDispatch()

  const forwardToChat = async () => {

    if (chat === undefined) {
      if (token) {
        setLoading(true)
        const response = await apiChatCreate(token, profileId)
        dispatch(chatCreateAction({
          chat: response
        }))
        setLoading(false)
      }
    } else {
      dispatch(chatSetActiveAction({
        activeChatId: chat.id
      }))
    }
    navigate(ROUTES.CHAT)

  }

  return (
    <button className="profile-interaction-chat-button profile-button" onClick={forwardToChat}>
      {loading ? (
        <img
          className="u-button-loader-icon"
          src="/images/icons/common/loader.svg"
          alt="Loading icon"
        />
      ) : (
        <img className="profile-interaction-chat-button-icon" src="/images/icons/profile/chat.svg" alt=""/>
      )}
      Message
    </button>
  )

}

const Flex = ({flex: initFlex, profileId}: {flex: 0 | 1, profileId: number}) => {

  const [flex, setFlex] = useState(initFlex)
  const token = useSelector(state => state.user.token)

  const changeFlex = () => {

    if (token) {
      apiProfileFlex(token, profileId)
    }
    setFlex(flex === 1 ? 0 : 1)

  }

  return (
    <button
      className={`profile-interaction-icon profile-text-color profile-border-color ${flex === 1 ? 'active' : ''}`}
      onClick={changeFlex}
    >
      <HeyIcon/>
    </button>
  )
}

const Favorite = ({favorite: initFavorite, profileId}: {favorite: 0 | 1, profileId: number}) => {

  const [favorite, setFavorite] = useState(initFavorite)
  const token = useSelector(state => state.user.token)

  const changeFavorite = () => {

    if (token) {
      apiProfileFavourite(token, profileId)
    }
    setFavorite(favorite === 1 ? 0 : 1)

  }

  return (
    <button
      className={`profile-interaction-icon profile-text-color profile-border-color ${favorite === 1 ? 'active' : ''}`}
      onClick={changeFavorite}
    >
      <FavoriteIcon/>
    </button>
  )
}

const Note = ({note, onClick}: {note: string | null, onClick: () => void}) => {

  return (
    <button
      onClick={onClick}
      className={`profile-interaction-icon profile-text-color profile-border-color ${(note && true && note.length > 0)? 'active' : ''}`}
    >
      <NoteCircleIcon/>
    </button>
  )
}

export default function ProfileInteraction(
  {
    profile,
    withChat,
    onChangeBlock,
    onChangeNote
  }: {
    profile: USER_PROFILE,
    withChat: boolean
    onChangeNote: (note: string) => void
    onChangeBlock: (block: 0 | 1) => void
  }
) {

  const [noteModalOpen, openNoteModal, closeNoteModal] = useModal()

  console.log(profile);

  return (
    <div className="profile-interaction">
      <Modal
        open={noteModalOpen}
        content={(
          <NoteModal
            onCancel={closeNoteModal}
            onChange={onChangeNote}
            note={profile.note}
            profileId={profile.id}
          />
        )}
        onClose={closeNoteModal}
      />
      {withChat && (
        <span className="profile-interaction-label">Interaction with the user</span>
      )}
      <div className="profile-interaction-icons">
        {withChat && (
          <ChatButton profileId={profile.id}/>
        )}
        <div className="profile-interactions-icons-right">
          <Flex
            flex={profile.flex}
            profileId={profile.id}
          />
          <Favorite
            favorite={profile.favourite}
            profileId={profile.id}
          />
          {profile.note && true && profile.note.length > 0 && (
            <Note
              onClick={openNoteModal}
              note={profile.note}
            />
          )}
        </div>
      </div>
      <MoreInteraction
        profile={profile}
        openNoteModal={openNoteModal}
        onChangeBlock={onChangeBlock}
      />
    </div>
  )

}
