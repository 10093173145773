import Cookies from "js-cookie";
import {ActionProps} from "./store";
import {PROFILE, PROFILE_TYPE} from "../types/models/profile";
import {USER_TYPE} from "../types/models/user";
import {apiUserUpdate} from "../actions/userActions";
import {
  apiProfileChangeActiveType,
  apiProfileCreate,
  apiProfileDelete,
  apiProfileUpdate, apiProfileVisit
} from "../actions/profileActions";

const USER_SIGN_IN = 'USER_SIGN_IN';
const USER_SIGN_OUT = 'USER_SIGN_OUT';
const PROFILE_CREATE = 'PROFILE_CREATE';
const PROFILE_UPDATE = 'PROFILE_UPDATE';
const PROFILE_DELETE = 'PROFILE_DELETE';
const CHANGE_ACTIVE_PROFILE_TYPE = 'CHANGE_ACTIVE_PROFILE_TYPE'
const USER_UPDATE = 'USER_UPDATE'
const USER_SET_VISIBLES = 'USER_SET_VISIBLES'
const VISIT_PROFILE = 'VISIT_PROFILE'

export type USER_STATE_TYPE = {
  isLoggedIn: boolean,
  user: USER_TYPE | null,
  token: null | string
  profiles: PROFILE[],
  activeType: null | PROFILE_TYPE,
  visibles: number[]
}

const defaultState = {
  isLoggedIn: false,
  user: null,
  token: null,
  profiles: [],
  activeType: null,
  visibles: []
};

const getActiveProfileType = (profiles: PROFILE[]) => {

  if (profiles.length === 0) {
    return null
  }

  const activeProfile = profiles.find(profile => profile.active === 1)
  if (activeProfile) {
    return activeProfile.type
  }

  return profiles[0].type

}

export const userReducer = (state: USER_STATE_TYPE = defaultState, action: ActionProps) => {
  switch (action.type) {
    case USER_SIGN_IN:
      Cookies.set('token', action.payload.token, { path: '/' })
      return {
        ...state,
        isLoggedIn: true,
        user: action.payload.user,
        token: action.payload.token,
        profiles: action.payload.profiles,
        activeType: getActiveProfileType(action.payload.profiles)
      };
    case USER_SIGN_OUT:
      Cookies.remove('token', { path: '/' })
      return {...state, isLoggedIn: false, user: null, token: null, searchSettings: []};
    case USER_SET_VISIBLES:
      return {
        ...state,
        visibles: action.payload.visibles
      };
    case VISIT_PROFILE:
      if (state.token) {
        apiProfileVisit(state.token, action.payload.profile_id)
      }
      return {
        ...state,
        visibles: [
          ...state.visibles,
          parseInt(action.payload.profile_id)
        ]
      };
    case PROFILE_CREATE:
      if (state.token) {
        apiProfileChangeActiveType(state.token, action.payload.profile.type)
        apiProfileCreate(state.token, action.payload.profile)
      }
      return {
        ...state,
        activeType: action.payload.profile.type,
        profiles: [
          ...state.profiles,
          action.payload.profile
        ]
      }
    case PROFILE_DELETE:
      const profile = state.profiles.find(profile => profile.type === action.payload.type)
      if (profile) {
        if (state.token) {
          apiProfileDelete(state.token, action.payload.type)
        }
        const newProfiles = [...state.profiles.filter(_profile => _profile.id !== profile.id)]
        return {
          ...state,
          activeType: getActiveProfileType(newProfiles),
          profiles: newProfiles
        }
      }
      return {
        ...state
      }
    case PROFILE_UPDATE:
      if (state.token) {
        apiProfileUpdate(state.token, action.payload.id, action.payload)
      }
      return {
        ...state,
        profiles: [...state.profiles.map(profile => {
          if (profile.id === action.payload.id) {
            return {
              ...profile,
              ...action.payload
            }
          }
          return profile
        })]
      }
    case USER_UPDATE:
      if (state.token && state.user) {
        apiUserUpdate(state.token, state.user.id, {
          ...state.user,
          ...action.payload.values
        })
      }
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload.values
        }
      }
    case CHANGE_ACTIVE_PROFILE_TYPE:
      if (state.token) {
        apiProfileChangeActiveType(state.token, action.payload.type)
      }
      return {
        ...state,
        activeType: action.payload.type
      }
    default:
      return state;
  }
};

export const userSignInAction = (payload : object) => ({type: USER_SIGN_IN, payload});
export const userSignOutAction = () => ({type: USER_SIGN_OUT});
export const profileCreateAction = (payload: object) => ({type: PROFILE_CREATE, payload})
export const profileDeleteAction = (payload: object) => ({type: PROFILE_DELETE, payload})
export const profileUpdateAction = (payload: object) => ({type: PROFILE_UPDATE, payload})
export const changeActiveTypeAction = (payload: object) => ({type: CHANGE_ACTIVE_PROFILE_TYPE, payload})
export const userUpdateAction = (payload: object) => ({type: USER_UPDATE, payload})
export const userSetVisiblesAction = (payload: object) => ({type: USER_SET_VISIBLES, payload})
export const userVisit = (payload: object) => ({type: VISIT_PROFILE, payload})
