import { useState } from 'react';
import { useDispatch } from "react-redux";
import { apiUserResendEmail } from "../../../actions/userActions";
import ResendTimer from '../../../components/ResendTimer';
import LogoWithIcon from "../../../icons/LogoWithIcon";
import { sendSmallNotification } from "../../../utils";

export default function EmailConfirm({ onBack, email, token }: { onBack: () => void, email: string, token: string }) {

  const [timerActive, setTimerActive] = useState(true);
  const dispatch = useDispatch()

  const resend = async () => {
    setTimerActive(true);
    await apiUserResendEmail(token)
    sendSmallNotification(dispatch, {
      title: 'New confirmation email sent!',
      type: 'success'
    })

  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon />
        </div>
        <h3 className="sign-up-title">Email Verification</h3>
        <div className="sign-up-text">
          We’ve sent a confirmation email to <b>{email}</b> with a link to confirm your address. Please check your email and click on the link there. If you don’t find it, please check your spam/junk folder.
        </div>
        {timerActive && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <ResendTimer seconds={60} onFinish={() => setTimerActive(false)} />
          </div>
        )}
        {!timerActive && (
          <div className="sign-up-resend">
            Didn't get link? <span className="sign-up-resend-link" onClick={resend}>Resend</span>
          </div>
        )}
        <div onClick={onBack} className="sign-up-back">Back</div>
      </div>
    </div>
  )

}
