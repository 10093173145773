import React from 'react'
import RadioSwitcherElement from './components/RadioSwitcherElement'
import './RadioSwitcher.css'

export type RadioSwitcherElementType = {
  label: string,
  id: string
}

export function RadioSwitcher({elements, selected, onChange}: {
  elements: RadioSwitcherElementType[],
  selected: string | null
  onChange: (selected: string) => void
}) {

  return (
    <div className="u-radio-switcher">
      {elements.map(element => {
        return (
          <RadioSwitcherElement
            element={element}
            key={element.id}
            selected={selected}
            onClick={() => {
              onChange(element.id)
            }}
          />
        )
      })}
    </div>
  )

}
