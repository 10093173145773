export enum ONBOARDING_STEP {
    SEX = 'sex',
    LOCATION = 'location',
    PROFILE_TYPE = 'profileType',
    NAME = 'name',
    AVATAR = 'avatar',
    PUBLIC_PHOTOS = 'publicPhotos',
    PRIVATE_PHOTOS = 'privatePhotos',
    BIRTHDATE = 'birthdate',
    ABOUT = 'about',
    OTHER = 'other',
  }
 
  export enum ROUTES {
    LOGIN = "/login",
    REGISTER = "/register",
    RESTORE = "/restore",
    RESET_PASSWORD = "/reset_password",
    PEOPLES = "/peoples",
    PEOPLES_ID = "/peoples/:id",
    MESSAGES = "/messages",
    PROFILE = "/profile",
    CHAT = "/chat",
    PROFILE_CREATE_FRIEND = "/profile/create/friend",
    PROFILE_CREATE_FLIRT = "/profile/create/flirt",
    PROFILE_CREATE_FUN = "/profile/create/fun",
    PROFILE_EDIT_FRIEND = "/profile/edit/friend",
    PROFILE_EDIT_FLIRT = "/profile/edit/flirt",
    PROFILE_EDIT_FUN = "/profile/edit/fun",
    ON_BOARDING = "/on-boarding",
    STATS = "/stats",
    NOTIFICATIONS = "/notifications",
    SETTINGS = "/settings",
    SETTINGS_BLOCKED_USERS = "/settings/blocked_users",
    SETTINGS_FAVORITES_USERS = "/settings/favorites_users",
    SEARCH = "/search",
    DEFAULT = "*"
  }
  