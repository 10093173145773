import React, { useState } from 'react';
import { NavLink } from "react-router-dom";
import { RadioSwitcher } from "../../components";
import './SignUp.scss';
import CodeConfirm from "./components/CodeConfirm";
import EmailConfirm from "./components/EmailConfirm";
import SignUpEmail from "./components/SignUpEmail";
import SignUpPhone from "./components/SignUpPhone";
import { registerTypes } from "./constants";
import LogoWithIcon from '../../icons/LogoWithIcon';

export function SignUp() {

  const [type, setType] = useState<null | string>('email')

  const [confirmId, setConfirmId] = useState<null | number>(null)
  const [phone, setPhone] = useState<null | string>(null)

  const [emailConfirm, setEmailConfirm] = useState<null | string>(null)
  const [token, setToken] = useState<null | string>(null)

  if (emailConfirm && token) {
    return (
      <EmailConfirm
        email={emailConfirm}
        token={token}
        onBack={() => {
          setEmailConfirm(null)
          setToken(null)
        }} />
    )
  }

  if (confirmId !== null && phone) {
    return (
      <CodeConfirm
        phone={phone}
        confirmId={confirmId}
        onBack={() => {
          setConfirmId(null)
          setPhone(null)
        }}
      />
    )
  }

  return (

    <div className="promo-page">
      <div className="promo-page-container">
        <div className="promo-page-left">
          <div className="promo-page-form">
            <div className="sign-up-logo-with-icon">
              <LogoWithIcon />
            </div>
            <h3 className="sign-up-title">Sign Up</h3>
            <span className="sign-up-text">Get your free Hey.Hey.Hey account today.</span>
            <div className="sign-up-switcher">
              <RadioSwitcher
                elements={registerTypes}
                selected={type}
                onChange={(_type) => setType(_type)}
              />
            </div>
            {type === 'email' && (
              <SignUpEmail
                onSetActivationEmail={(email, token) => {
                  setEmailConfirm(email)
                  setToken(token)
                }}
              />
            )}
            {type === 'phone' && <SignUpPhone needAgreement={true} onChange={(confirmId, phone) => {
              setConfirmId(confirmId)
              setPhone(phone)
            }} />}
            <span className="sign-up-sign-in-link">Already have an account? <NavLink className="sign-up-sign-in-link-href" to="/login">Sign In</NavLink></span>
          </div>
        </div>
        <div className="promo-page-right">
          <img className="promo-page-right-image" src={"/images/iphone.png"} />
        </div>
      </div>
    </div>
  )

}
