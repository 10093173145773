import React from 'react'
import { UserIcon } from "./UserIcon";
import LocationIcon from "../../../icons/users/LocationIcon";
import { NavLink } from "react-router-dom";
import { USER_PROFILE } from "../../../types/models/profile";
import { countDistance, getFullImageUrl } from "../../../utils";
import ImageIcon from '../../../components/ui/PhotosInput/icons/ImageIcon';
import { useSelector } from "react-redux";
import EyeIcon from "../icons/EyeIcon";
import muslLogo from '../../../images/musl-logo.png';
import { ROUTES } from '../../../types/enum';

const lookingForMappedIcons: any = {
  'Marriage': 'M',
  'Long-term relationship': 'LT',
  'Just dating': 'D',
  'A great date or two': 'D'
}

export default function User({ profile }: { profile: USER_PROFILE }) {

  const visibles = useSelector(state => state.user.visibles)
  const isVisited = visibles.find(visible => visible === profile.id) !== undefined

  const getAdditionalIcons = () => {

    if (profile.type === 'flirt' && profile.looking_for) {

      const values = profile.looking_for.split(',')
      if (values.length > 0) {
        return (
          <div className="user-location-lf-icons">
            <span className="user-location-lf-icon profile-background-color">
              {values.length > 1 ? '2+' : lookingForMappedIcons[values[0]]}
            </span>
          </div>
        )
      }

    }

    return <div />

  }

  return (
    <NavLink to={`${ROUTES.PEOPLES}/${profile.id}`} className="user">
      <div className="user-photo-area">
        {isVisited ? (
          <span className="user-photo-visible-icon profile-text-color profile-background-color-opacity">
            <EyeIcon />
            Viewed
          </span>
        ) : null}
        {profile.adult && profile.adult_status !== 3 ? (
          <div className="user-photo-area-image">
            <ImageIcon />
          </div>
        ) : (
          <img className="user-photo-area-image" src={getFullImageUrl(profile.avatar)} alt="" />
        )}
        <div className='img-bottom-icons'>
          {profile.distance && (
            <div className="user-location-icon">
              <UserIcon icon={<LocationIcon />} label={countDistance(profile.distance)} />
            </div>
          )}
          {!profile.hey && (
            <div className="musl-icon">
              <img src={muslLogo} />
            </div>
          )}
          {getAdditionalIcons()}
        </div>
      </div>
      <div className="user-bottom">
        <span className="user-name profile-text-color">{profile.name}</span>
      </div>
    </NavLink>
  )

}
