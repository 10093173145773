import {
  PRIVACY_POLICY_URL,
  TERMS_OF_USE_URL
} from "../../constants";
import './Footer.scss';

export function Footer() {

  return (
    <footer className="footer">
      <div className="footer-container container">
        <div className="footer-logo-container">
          <a href="/">
            <img className="footer-logo-image" src="/images/logo.svg" alt="HeyHeyHey log" />
          </a>
          <span className="footer-copyright">© 2024 HEYHEYHEY. ALL RIGHTS RESERVED</span>
        </div>
        <div className="footer-menu-container">
          <div className="footer-menu-item">
            <a href={TERMS_OF_USE_URL} target={"_blank"}>Terms & Conditions</a>
          </div>
          <div className="footer-menu-item">
            <a href={PRIVACY_POLICY_URL} target={"_blank"}>Privacy Policy</a>
          </div>
        </div>
      </div>
    </footer>
  )

}
