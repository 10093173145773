import axios from "axios";
import { useEffect, useState } from "react";
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from "react-redux";
import { apiProfilesSearch } from "../../actions/profileActions";
import Loader from "../../components/ui/Loader/Loader";
import { USER_PROFILE } from "../../types/models/profile";
import './Users.scss';
import User from "./components/User";
import UsersTop from "./components/UsersTop";
import { SearchValue } from "../../components/FiltersModal/FiltersModal";
import DefaultLoader from "../../components/ui/Loader/DefaultLoader";

const CancelToken = axios.CancelToken;
let source: any;

export function Users() {

  const activeType = useSelector(state => state.user.activeType)
  const token = useSelector(state => state.user.token)

  const [offset, setOffset] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [peoples, setPeoples] = useState<USER_PROFILE[]>([])
  const [loading, setLoading] = useState(false)
  const [values, setValues] = useState<SearchValue[]>([])
  const [search, setSearch] = useState<string>('')
  const [onlyFavorite, setOnlyFavorite] = useState<boolean>(false)

  useEffect(() => {
    loadUsers()
  }, [search, values, onlyFavorite])

  const fetchUsers = async (offset: number) => {

    if (token && activeType) {
      if (source) {
        source.cancel();
      }
      source = CancelToken.source();
      return await apiProfilesSearch(
        source.token,
        token,
        activeType,
        24,
        offset,
        search,
        JSON.stringify(values.map(value => JSON.stringify(value))),
        onlyFavorite
      )
    }

    return []

  }

  const loadUsers = async () => {

    setLoading(true)
    setOffset(0)
    const response = await fetchUsers(offset)
    setPeoples(response)
    setLoading(false)

  }

  const loadMore = async () => {

    const response = await fetchUsers(offset + 24)
    if (response.length === 0) {
      setHasMore(false)
    } else {
      setPeoples([
        ...peoples,
        ...response
      ])
      setOffset(offset + 24)
    }

  }

  return (
    <div className="users-wrapper">
      <div className="container">
        <UsersTop
          search={search}
          onChangeSearch={_search => setSearch(_search)}
          values={values}
          onChangeValues={_values => setValues(_values)}
          onlyFavorite={onlyFavorite}
          onChangeOnlyFavorite={_onlyFavorite => {
            setOnlyFavorite(_onlyFavorite)
          }}
        />
        {loading ? (
          <div className="users-loader">
            <Loader />
          </div>
        ) : (
          <>
            {peoples.length === 0 ? (
              <div className="users-empty">
                <img src="/images/icons/common/search.svg" alt="" className="users-empty-icon" />
                <span className="users-empty-title">No users match your filter</span>
                <span className="users-empty-text">Change filter settings to see more people to communicate and flirt</span>
              </div>
            ) : (
              <InfiniteScroll
                className='users'
                dataLength={peoples.length} //This is important field to render the next data
                next={loadMore}
                hasMore={hasMore}
                loader={
                  <div style={{
                    position: 'relative',
                    width: '100%',
                    height: '80px'
                  }}>
                    <Loader />
                  </div>
                }
              >
                {peoples.map(people => {
                  return (
                    <div className="users-element">
                      <User profile={people} />
                    </div>
                  )
                })}
              </InfiniteScroll>
            )}
          </>
        )}
      </div>
    </div>
  )

}
