import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingUpdateAction} from "../../../reducers/onboarding";
import {USER_TYPE} from "../../../types/models/user";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {useNavigate} from "react-router-dom";
import { ROUTES } from "../../../types/enum";

const getPrevStep = (user: USER_TYPE | null) => {

  if (user === null || user.userLocation.location === null) {
    return 'location'
  }

  if (user.sex == null) {
    return 'sex'
  }

  return 'profileType'
}

export function OnBoardingName({profileType}: {profileType: PROFILE_TYPE | false}) {

  const name = useSelector(state => state.onBoarding.name)
  const user = useSelector(state => state.user.user)

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const onBack = () => {
    if (profileType) {
      navigate(ROUTES.PROFILE)
    } else {
      dispatch(onBoardingUpdateAction({
        values: {
          step: getPrevStep(user)
        }
      }))
    }
  }

  const submit = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'avatar'
      }
    }))
  }

  const changeName = (value: string) => {
    dispatch(onBoardingUpdateAction({
      values: {
        name: value
      }
    }))
  }

  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Enter a Profile name</span>
      <span className="on-boarding-text">And don&apos;t worry, you can change your profile name any time.</span>
      <input
        type="text"
        className="on-boarding-input"
        value={name}
        onChange={(event) => {
          changeName(event.target.value)
        }}
        maxLength={50}
      />
      <button
        disabled={name.length < 1}
        className="on-boarding-submit-button"
        onClick={submit}
      >
        Continue
      </button>
    </>
  )
}
