import { useDispatch, useSelector } from "react-redux";
import "./ProfileSwitcherPopUp.scss";
import { useRef } from "react";
import useClickOutside from "../../../hooks/useClickOutside";
import {
  applicationToggleNavDrawer,
  applicationToggleProfileSwitcherPopUp,
} from "../../../reducers/application";
import CloseIcon from "../../ui/Modal/icons/CloseIcon";
import { ProfileSwitcherProfile } from "../ProfileSwitcher";
import { NavLink, useNavigate } from "react-router-dom";
import { PROFILE_TYPE } from "../../../types/models/profile";
import { changeActiveTypeAction } from "../../../reducers/user";
import { PROFILE_TYPES, PROFILE_TYPES_LIST } from "../../../constants";
import { ROUTES } from "../../../types/enum";

const CreateProfileButton = ({ type }: { type: PROFILE_TYPE }) => {
  const dispatch = useDispatch();
  const profileData = PROFILE_TYPES[type]  

  return (
    <NavLink
      to={`/profile/create/${type}`}
      className={`left-switcher-picker-create-button left-switcher-picker-create-button-${type}`}
      onClick={() => {
        dispatch(applicationToggleProfileSwitcherPopUp());
      }}
    >
      Create
      <span className="uppercase">{profileData.label}</span>
      <img
        className="left-switcher-picker-create-button-icon"
        src={profileData.icon}
        alt=""
      />
      profile
    </NavLink>
  );
};

const ProfileSwitcherPopUp = () => {
  const profileSwitcherPopUpOpened = useSelector(
    (state) => state.application.profileSwitcherPopUp.isOpened
  );
  const navDrawerOpened = useSelector(
    (state) => state.application.navigationDrawer.isOpened
  );
  const profiles = useSelector((state) => state.user.profiles);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const popUpContentRef = useRef(null);

  const closePopUp = (e: React.MouseEvent<HTMLButtonElement>) => {
    e?.stopPropagation();
    if (profileSwitcherPopUpOpened) {
      dispatch(applicationToggleProfileSwitcherPopUp());
    }
  };

  useClickOutside(popUpContentRef, closePopUp);

  const changeType = (type: PROFILE_TYPE) => {
    dispatch(
      changeActiveTypeAction({
        type,
      })
    );
    dispatch(applicationToggleProfileSwitcherPopUp());
    if (navDrawerOpened) {
      dispatch(applicationToggleNavDrawer());
    }
    navigate(ROUTES.PROFILE);
  };

  if (!profileSwitcherPopUpOpened) {
    return null;
  }

  const missingProfileTypes = PROFILE_TYPES_LIST.filter(
    (type) => !profiles.some((profile) => profile.type === type)
  );

  return (
    <div
      className={`switcher-pop-up-overlay ${
        profileSwitcherPopUpOpened ? "active" : ""
      }`}
    >
      <div className="switcher-pop-up-content" ref={popUpContentRef}>
        <div className="switcher-pop-up-top">
          <span className="switcher-pop-up-label">Switch account</span>
          <button
            type="button"
            className="switcher-pop-up-close"
            onClick={closePopUp}
          >
            <CloseIcon />
          </button>
        </div>
        <>
          {profiles.map((profile) => {
            return (
              <ProfileSwitcherProfile profile={profile} onClick={changeType} />
            );
          })}
        </>
        {missingProfileTypes.length > 0 && (
          <>
            {missingProfileTypes.map((type) => (
              <CreateProfileButton key={type} type={type} />
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileSwitcherPopUp;
