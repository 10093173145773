import * as React from "react"

const NoteIcon = () => (
  <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_608_16310)">
      <path d="M35.7056 54.1673L54.6639 35.209" stroke="#6E6A7A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M35.7056 54.1673V37.9173C35.7056 37.199 35.9909 36.5101 36.4988 36.0022C37.0067 35.4943 37.6956 35.209 38.4139 35.209H54.6639V16.2507C54.6639 14.8141 54.0932 13.4363 53.0774 12.4205C52.0616 11.4047 50.6838 10.834 49.2472 10.834H16.7472C15.3106 10.834 13.9329 11.4047 12.9171 12.4205C11.9012 13.4363 11.3306 14.8141 11.3306 16.2507V48.7507C11.3306 50.1872 11.9012 51.565 12.9171 52.5808C13.9329 53.5966 15.3106 54.1673 16.7472 54.1673H35.7056Z" stroke="#6E6A7A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_608_16310">
        <rect width="65" height="65" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>


)
export default NoteIcon
