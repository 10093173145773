import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.scss";
import { userSession } from "./actions/userActions";
import { Lightbox } from "./components";
import Auth from "./components/Auth/Auth";
import NoAuth from "./components/NoAuth/NoAuth";
import useBreakpoints from "./hooks/useBreakpoints";
import MainLayout from "./layouts/MainLayout";
import {
  BlockedUsers,
  ChatPage,
  EditProfile,
  FavoritesUsers,
  Messages,
  MyProfile,
  Notifications,
  ResetPassword,
  Settings,
  SignUp,
  Stats,
  UserPage,
  Users,
} from "./pages";
import { OnBoarding } from "./pages/OnBoarding/OnBoarding";
import { RestorePassword } from "./pages/RestorePassword/RestorePassword";
import { SignIn } from "./pages/SignIn/SignIn";
import { store } from "./reducers/store";
import { userSignInAction } from "./reducers/user";
import InstallAppModal from "./components/InstallAppModal";
import { ROUTES } from "./types/enum";
import { PROFILE_TYPES_LIST } from "./constants";

function App() {
  const [loading, setLoading] = useState(true);

  const { lgUp } = useBreakpoints();

  useEffect(() => {
    checkSession();
  }, []);

  const checkSession = async () => {
    const session = await userSession();
    if (session === null) {
      setLoading(false);
    } else {
      store.dispatch(
        userSignInAction({
          user: session.user,
          profiles: session.profiles,
          token: session.token,
        })
      );
      setLoading(false);
    }
  };

  if (loading) {
    return <div />;
  }

  return (
    <Provider store={store}>
      <InstallAppModal />
      <MainLayout>
        <BrowserRouter>
          <Routes>
            {/* Routes for creating profiles */}
            <Route element={<Auth withSidebar={false} profileType="flirt" />}>
              {PROFILE_TYPES_LIST.map((type) => (
                <Route
                  key={type}
                  path={
                    ROUTES[
                      `PROFILE_CREATE_${type.toUpperCase()}` as keyof typeof ROUTES
                    ]
                  }
                  element={<OnBoarding profileType={type} />}
                />
              ))}
            </Route>

            {/* General routes */}
            <Route element={<Auth withSidebar={false} />}>
              <Route
                path={ROUTES.ON_BOARDING}
                element={<OnBoarding profileType={false} />}
              />
              <Route
                path={ROUTES.RESET_PASSWORD}
                element={<ResetPassword auth={true} />}
              />
            </Route>

            {/* Profile & Settings */}
            <Route element={<Auth withSidebar={lgUp} />}>
              <Route path={ROUTES.PROFILE} element={<MyProfile />} />
              {PROFILE_TYPES_LIST.map((type) => (
                <Route
                  key={type}
                  path={
                    ROUTES[
                      `PROFILE_EDIT_${type.toUpperCase()}` as keyof typeof ROUTES
                    ]
                  }
                  element={<EditProfile type={type} />}
                />
              ))}
              <Route path={ROUTES.STATS} element={<Stats />} />
              <Route path={ROUTES.NOTIFICATIONS} element={<Notifications />} />
              <Route path={ROUTES.SETTINGS} element={<Settings />} />
              <Route
                path={ROUTES.SETTINGS_BLOCKED_USERS}
                element={<BlockedUsers />}
              />
              <Route
                path={ROUTES.SETTINGS_FAVORITES_USERS}
                element={<FavoritesUsers />}
              />
              <Route path={ROUTES.MESSAGES} element={<Messages />} />
              <Route path={ROUTES.PEOPLES_ID} element={<UserPage />} />
              <Route path={ROUTES.PEOPLES} element={<Users />} />
              <Route path={ROUTES.CHAT} element={<ChatPage />} />
            </Route>

            {/* NoAuth routes */}
            <Route element={<NoAuth />}>
              <Route path={ROUTES.LOGIN} element={<SignIn />} />
              <Route path={ROUTES.REGISTER} element={<SignUp />} />
              <Route path={ROUTES.RESTORE} element={<RestorePassword />} />
            </Route>

            {/* Default redirect */}
            <Route
              path={ROUTES.DEFAULT}
              element={<Navigate to={ROUTES.LOGIN} />}
            />
          </Routes>
        </BrowserRouter>
        <Lightbox />
      </MainLayout>
    </Provider>
  );
}

export default App;
