import {PROFILE_TYPES} from "../../../constants";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {InfoIcon} from "../../../components";

export function ProfileType({type, checked, onClick}: {type: PROFILE_TYPE, checked: boolean, onClick: () => void}) {
  const profileData = PROFILE_TYPES[type]

  return (
    <div className="on-boarding-profile-type">
      <div
        className="on-boarding-profile-type-inner"
        onClick={onClick}
        style={{backgroundColor: checked ? PROFILE_TYPES[type].backgroundColor : 'transparent'}}
      >
        <img src={profileData.icon} alt="" className="on-boarding-profile-type-icon"/>
        <span className="on-boarding-profile-type-label" style={{color: profileData.color}}>
          {profileData.label}
        </span>
        {checked &&  <img src={'/images/icons/types/check.svg'} alt="" className="on-boarding-profile-type-check"/>}
      </div>
      <InfoIcon
        content={(
          <>
            <span style={{color: profileData.color}}>
              {profileData.label}
            </span>{` `}{profileData.infoText}
          </>
        )}
      />
    </div>
  )
}
