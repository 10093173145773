import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { apiUserSendResetPasswordEmail } from "../../../actions/userActions";
import { sendSmallNotification } from "../../../utils";
import LogoWithIcon from "../../../icons/LogoWithIcon";
import ResendTimer from '../../../components/ResendTimer';
import { ROUTES } from '../../../types/enum';

export function RestorePasswordActivation({ email }: { email: string }) {
  const [timerActive, setTimerActive] = useState(true);
  const dispatch = useDispatch()

  const resend = async () => {

    setTimerActive(true);
    await apiUserSendResetPasswordEmail(email)
    sendSmallNotification(dispatch, {
      title: 'New reset email sent!',
      type: 'success'
    });

  }


  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon />
        </div>
        <span className="sign-up-title">Restore password</span>
        <span className="sign-up-text">We sent the password recovery link to your e-mail <b>{email}</b>. Please check your email.</span>
        {timerActive && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <ResendTimer seconds={60} onFinish={() => setTimerActive(false)} />
          </div>
        )}
        {!timerActive && (
          <span className="sign-up-resend">Didn't get link? <span onClick={resend} className="sign-up-resend-link">Resend</span></span>
        )}
        <NavLink to={ROUTES.LOGIN} className="sign-up-back">Back</NavLink>
      </div>
    </div>
  )

}
