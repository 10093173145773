import React, {useEffect} from 'react'
import {Outlet, useLocation, useSearchParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {apiUserCheckActivationCode} from "../../actions/userActions";
import {userSignInAction} from "../../reducers/user";
import NavigationDrawer from "../NavigationDrawer/NavigationDrawer";
import { ROUTES } from '../../types/enum';

export default function NoAuth({}) {

  const isLoggedIn = useSelector(state => state.user.isLoggedIn)
  const navigate = useNavigate()
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch()

  useEffect(() => {
    checkActivationCode()
  }, [])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate(ROUTES.PROFILE)
    }

  }, [isLoggedIn])

  const checkActivationCode = async () => {
    const activationCode = searchParams.get('activation_code')
    if (activationCode) {
      const response = await apiUserCheckActivationCode(activationCode)
      if (response.status) {
        dispatch(userSignInAction({
          user: response.user,
          profiles: response.profiles,
          token: response.token
        }));
        window.location.reload()
      }
    }
  }

  return (
   <>
     <Outlet/>
     <NavigationDrawer />
   </>
  )

}
