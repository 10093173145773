import './Chat.css'
import {useDispatch} from "react-redux";
import {chatSetActiveAction} from "../../../reducers/chat";
import {CHAT_TYPE} from "../../../types/models/chat";
import {useNavigate} from "react-router-dom";
import {getFullImageUrl} from "../../../utils";
// @ts-ignore
import timeago from 'epoch-timeago';
import { ROUTES } from "../../../types/enum";

export function Chat({type = 'default', chat}: {type?: 'small' | 'default', chat: CHAT_TYPE}) {

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectChat = () => {

    dispatch(chatSetActiveAction({
      activeChatId: chat.id
    }))
    navigate(ROUTES.CHAT)
  }

 return (
   <div onClick={selectChat} className={`chat ${type === 'small' ? 'small' : ''}`}>
     <img src={getFullImageUrl(chat.profile.photo)} alt="" className="chat-profile-photo"/>
     <div className="chat-profile">
       <div className="chat-profile-top">
         <span className="chat-profile-name">{chat.profile.name}</span>
       </div>
       <span className="chat-profile-text">{chat.lastMessage && chat.lastMessage.text}</span>
     </div>
     <div className="chat-right">
       <span className="chat-time">{chat.lastMessage && timeago(chat.lastMessage.timestamp * 1000)}</span>
     </div>
   </div>
 )

}
