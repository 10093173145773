import React, {useEffect, useState} from 'react'
import {NavLink} from "react-router-dom";
import './BlockedUsers.css'
import {useSelector} from "react-redux";
import {USER_PROFILE} from "../../types/models/profile";
import {apiProfilesBlocked} from "../../actions/profileActions";
import Loader from "../../components/ui/Loader/Loader";
import User from "../Users/components/User";
import { ROUTES } from '../../types/enum';

export function BlockedUsers() {

  const activeType = useSelector(state => state.user.activeType)
  const token = useSelector(state => state.user.token)

  const [peoples, setPeoples] = useState<USER_PROFILE[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadUsers()
  }, [activeType])

  const loadUsers = async () => {

    if (token && activeType) {
      setLoading(true)
      const response = await apiProfilesBlocked(token, activeType)
      setPeoples(response)
      setLoading(false)
    }

  }

  return (
    <div className="users-wrapper">
      <div className="container">
        <div className="page-with-menu-top">
          <NavLink to={ROUTES.SETTINGS} className="page-with-menu-top-back-icon">
            <img src="/images/icons/common/angle-left-black.svg" alt=""/>
          </NavLink>
          <span className="page-with-menu-top-title">Blocked users</span>
        </div>
        {loading ? (
          <div className="users-loader">
            <Loader/>
          </div>
        ) : (
          <>
            {peoples.length === 0 ? (
              <div className="blocked-users-empty">
                <img src="/images/icons/common/blocked.svg" alt="" className="blocked-users-empty-icon"/>
                <span className="blocked-users-empty-title">You haven’t blocked anyone.</span>
                <span className="blocked-users-empty-text">To block a user, visit the user’s profile page and tap “More interaction with a user” and select “Block this user” </span>
              </div>
            ) : (
              <div className="users">
                {peoples.map(people => {
                  return (
                    <div className="users-element">
                      <User profile={people}/>
                    </div>
                  )
                })}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )

}
