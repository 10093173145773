import * as React from "react"

const BlockIcon = () => (
  <svg width="66" height="65" viewBox="0 0 66 65" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_608_17260)">
      <path d="M21.8281 24.9955C22.3782 26.7573 23.3452 28.3602 24.6469 29.6681C25.9487 30.976 27.5468 31.9501 29.3054 32.5078M39.3295 30.9493C40.6936 29.9865 41.8304 28.7365 42.6601 27.2872C43.4897 25.8378 43.992 24.2243 44.1318 22.56C44.2715 20.8957 44.0452 19.2209 43.4689 17.6534C42.8925 16.086 41.98 14.6638 40.7955 13.4868C39.6111 12.3098 38.1834 11.4067 36.6127 10.8407C35.0421 10.2748 33.3666 10.0599 31.704 10.211C30.0415 10.3621 28.4322 10.8757 26.9892 11.7156C25.5462 12.5555 24.3046 13.7013 23.3516 15.0726" stroke="#6E6A7A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M41.127 42.6563C40.1114 41.6406 39.5325 40.625 38.4167 40.625H27.5833C24.7102 40.625 21.9547 41.7664 19.923 43.798C17.8914 45.8297 16.75 48.5852 16.75 51.4583C16.75 51.4583 16.7535 56.1982 17.7676 56.875C26.9052 62.9739 42.6582 60.9375 48.7517 56.875C49.7676 56.1977 49.25 51.4583 49.25 51.4583C49.25 50.3235 49.252 50.7813 48.2364 49.7656" stroke="#6E6A7A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.625 10.1562L57.375 58.9062" stroke="#6E6A7A" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_608_17260">
        <rect width="65" height="65" fill="white" transform="translate(0.5)"/>
      </clipPath>
    </defs>
  </svg>
)
export default BlockIcon
