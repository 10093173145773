import React, {useState} from 'react'
import {Button, TextInput} from "../../components";
import {sendSmallNotification, validateEmail} from "../../utils";
import {RestorePasswordActivation} from "./components/RestorePasswordActivation";
import {NavLink} from "react-router-dom";
import {apiUserSendResetPasswordEmail} from "../../actions/userActions";
import {useDispatch} from "react-redux";
import LogoWithIcon from "../../icons/LogoWithIcon";
import { ROUTES } from '../../types/enum';

export function RestorePassword() {

  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')

  const [submitLoading, setSubmitLoading] = useState(false)
  const [restoredEmail, setRestoredEmail] = useState<null | string>(null)

  const onSubmit = async () => {

    setSubmitLoading(true)
    const response = await apiUserSendResetPasswordEmail(email)
    if (response.status === 'false') {
      sendSmallNotification(dispatch,{
        title: 'This email is not registered',
        message: 'Try to type it once again',
        type: 'error'
      })
      setSubmitLoading(false)
      return
    }

    if (response.status) {
      setRestoredEmail(email)
    }

  }

  const checkSubmitButtonDisabled = () => {
    return !validateEmail(email) || submitLoading
  }

  if (restoredEmail !== null) {
      return (
        <RestorePasswordActivation
          email={restoredEmail}
        />
      )
  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon/>
        </div>
        <h3 className="sign-up-title">Restore password</h3>
        <span className="sign-up-text">Enter the email address you used when you created account. We will send you instructions on how to recover your password</span>
        <div className="sign-up-field">
          <TextInput
            id={'email'}
            label={'Email'}
            type={'email'}
            value={email}
            onChange={(_email) => {
              setEmail(_email)
              setEmailError('')
            }}
            placeholder={'Enter your email'}
            error={emailError}
          />
        </div>
        <div className="sign-up-button">
          <Button label={'Continue'} onClick={() => {onSubmit()}} disabled={checkSubmitButtonDisabled()} loading={submitLoading}/>
        </div>
        <div className='sign-up-back'>
          <NavLink to={ROUTES.LOGIN} className="sign-up-back">Back</NavLink>
        </div>
      </div>
    </div>
  )

}
