import { useState } from "react";
import "./ProfileSwitcher.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { PROFILE, PROFILE_TYPE } from "../../types/models/profile";
import { useDispatch, useSelector } from "react-redux";
import {
  PROFILE_TYPES_LIST,
  PROFILE_TYPES
} from "../../constants";
import { getFullImageUrl, getProfile, getProfileColor } from "../../utils";
import { changeActiveTypeAction } from "../../reducers/user";
import CheckMarkIcon from "../../icons/CheckMarkIcon";
import useBreakpoints from "../../hooks/useBreakpoints";
import { ROUTES } from "../../types/enum";


const CreateProfileButton = ({type, profiles}: {type: PROFILE_TYPE, profiles: PROFILE[]}) => {
  const profileData = PROFILE_TYPES[type];

  if (profiles.find(profile => profile.type === type) === undefined) {
    return (
      <NavLink
        to={`/profile/create/${type}`}
        className={`left-switcher-picker-create-button left-switcher-picker-create-button-${type}`}
      >
        Create
        <span className="uppercase">{profileData.label}</span>
        <img className="left-switcher-picker-create-button-icon" src={profileData.icon} alt=""/>
        profile
      </NavLink>
    )
  }

  return <></>

}

export function ProfileSwitcher({}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const activeType = useSelector((state) => state.user.activeType);
  
  const profiles = useSelector((state) => state.user.profiles);

  const profile = getProfile(profiles, activeType);

  const { lgUp } = useBreakpoints();

  const [opened, setOpened] = useState(false);

  const { pathname } = useLocation();

  if (profile === null) {
    return <div />;
  }

  const changeType = (type: PROFILE_TYPE) => {
    dispatch(
      changeActiveTypeAction({
        type,
      })
    );
    setOpened(false);
    navigate(ROUTES.PROFILE);

  };

  const profileData = PROFILE_TYPES[profile.type];

  return (
    <div className="left-switcher">
      <div className={`left-switcher-picker${opened ? " opened" : ""}`}>
        <div
          className="left-switcher-picker-top"
          onClick={() => {
            setOpened(!opened);
          }}
        >
          <img
            src={profileData.icon}
            alt=""
            className="left-switcher-picker-icon"
          />
          <span
            style={{ color: profileData.color}}
            className="left-switcher-picker-label"
          >
            {profileData.label}
          </span>
          <img
            src={profileData.arrow}
            alt=""
            className="left-switcher-picker-arrow"
          />
        </div>
        {opened && (
          <div className="left-switcher-picker-content">
            {profiles.map((profile) => {
              return (
                <ProfileSwitcherProfile
                  profile={profile}
                  key={profile.id}
                  onClick={changeType}
                />
              );
            })}
             {PROFILE_TYPES_LIST.filter(
              (type) => !profiles.some((profile) => profile.type === type)
            ).map((type) => (
              <CreateProfileButton key={type} type={type} profiles={profiles} />
            ))}
          </div>
        )}
      </div>
      {["/peoples", "/messages"].indexOf(pathname) !== -1 ||
      pathname.substring(0, 9) === "/messages" ? (
        <>
          <SwitcherCurrentProfile profile={profile} />
        </>
      ) : (
        <NavLink
          to="/peoples"
          className="left-switcher-button"
          style={
            lgUp
              ? { backgroundColor: getProfileColor(activeType) }
              : {
                  color: getProfileColor(activeType),
                  backgroundColor: "transparent",
                }
          }
        >
          <img
            src={
              lgUp
                ? "/images/icons/switcher/community.svg"
                : profileData.arrowLeft
            }
            alt=""
            className="left-switcher-button-icon"
          />
          Community Profiles
        </NavLink>
      )}
    </div>
  );
}

function SwitcherCurrentProfile({ profile }: { profile: PROFILE }) {
  return (
    <NavLink
      to={"/profile"}
      className="left-switcher-current-profile profile-background-color"
    >
      <img
        src={getFullImageUrl(profile.avatar)}
        alt=""
        className="left-switcher-current-profile-photo"
      />
      <span className="left-switcher-current-profile-name">{profile.name}</span>
    </NavLink>
  );
}

export function ProfileSwitcherProfile({
  profile,
  onClick,
}: {
  profile: PROFILE;
  onClick: (type: PROFILE_TYPE) => void;
}) {
  const activeType = useSelector((state) => state.user.activeType);
  const profileData = PROFILE_TYPES[profile.type];

  return (
    <div
      className="left-switcher-profile"
      onClick={() => {
        onClick(profile.type);
      }}
    >
      <img
        src={getFullImageUrl(profile.avatar)}
        alt=""
        className="left-switcher-profile-image"
      />
      <div className="left-switcher-profile-content">
        <span className="left-switcher-profile-name">{profile.name}</span>
        <div className="left-switcher-profile-type">
          <img
            src={profileData.icon}
            alt=""
            className="left-switcher-profile-type-icon"
          />
          <span
            style={{ color: profileData.color}}
            className="left-switcher-profile-type-label"
          >
            {profileData.label}
          </span>
        </div>
      </div>
      {activeType === profile.type && (
        <div className="left-switcher-profile-checkmark">
          <CheckMarkIcon />
        </div>
      )}
    </div>
  );
}
