import {useState} from 'react'
import './VisibilityModal.scss'
import VisibilityIcon from "./icons/VisibilityIcon";
import {PROFILE_TYPES} from "../../constants";
import { Switcher } from '../ui/Switcher/Switcher';
import {useSelector} from "react-redux";
import {PROFILE, USER_PROFILE} from "../../types/models/profile";
import {getFullImageUrl} from "../../utils";

const VisibilityElement = ({profile}: {profile: PROFILE}) => {

  const [checked, setChecked] = useState(true)
  const profileData = PROFILE_TYPES[profile.type]  
  

  return (
    <div className="visibility-modal-element">
      <img src={getFullImageUrl(profile.avatar)} alt="" className="visibility-modal-element-avatar"/>
      <img src={profileData.icon} className="visibility-modal-element-icon"/>
      <span className="visibility-modal-element-label">
        <span style={{color: profileData.color}}>{profileData.label}</span>
        {` `}profile
      </span>
      <div className="visibility-modal-element-switcher">
        <Switcher
          label={''}
          checked={checked}
          onChange={checked => {
            setChecked(checked)
          }}
        />
      </div>
    </div>
  )

}

export const VisibilityModal = ({profile, onCancel}: {profile: USER_PROFILE, onCancel: () => void}) => {

  const profiles = useSelector(state => state.user.profiles)

  return (
    <div className="visibility-modal">
      <div className="visibility-modal-icon">
        <VisibilityIcon/>
      </div>
      <span className="visibility-modal-title">Which of your profiles will Jane see?</span>
      <div className="visibility-modal-elements">
        {profiles.filter(_profile => _profile.type !== profile.type).map(_profile => {
          return (
            <VisibilityElement profile={_profile}/>
          )
        })}
      </div>
      <div className="modal-bottom">
        <button className="modal-cancel-button profile-border-color profile-text-color" onClick={onCancel}>Cancel</button>
        <button className="modal-submit-button profile-background-color" onClick={onCancel}>Save</button>
      </div>
    </div>
  )

}
