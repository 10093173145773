import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { apiStatsGet } from "../../actions/statsActions";
import Loader from "../../components/ui/Loader/Loader";
import Inbox from "../../icons/stats/Inbox";
import Outbox from "../../icons/stats/Outbox";
import Photos from "../../icons/stats/Photos";
import Private from "../../icons/stats/Private";
import Visible from "../../icons/stats/Visible";
import Waves from "../../icons/stats/Waves";
import './Stats.scss';
import { StatsElement } from "./components/StatsElement";

type StatsValues = {
  profileViews: number,
  waves: number,
  messagesSend: number,
  receivedMessages: number
  privatePhotos: number
  allPhotos: number
}

export function Stats() {

  const activeType = useSelector(state => state.user.activeType)
  const token = useSelector(state => state.user.token)

  const [tab, setTab] = useState<'7days' | 'total'>('7days')
  const [values, setValues] = useState<StatsValues>({
    profileViews: 0,
    waves: 0,
    receivedMessages: 0,
    messagesSend: 0,
    privatePhotos: 0,
    allPhotos: 0
  })
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    loadValues()
  }, [tab])

  const loadValues = async () => {

    if (token && activeType) {
      setLoading(true)
      const response = await apiStatsGet(token, activeType, tab)
      setValues({
        ...response
      })
      setLoading(false)
    }

  }

  return (
    <>
      <div className="page-with-menu-top">
        <span className="page-with-menu-top-title">Stats</span>
        <div className="stats-switcher">
          <span className={`stats-switcher-element ${tab === '7days' ? 'active' : ''}`} onClick={() => { setTab('7days') }}>Last 7 days</span>
          <span className={`stats-switcher-element ${tab === 'total' ? 'active' : ''}`} onClick={() => { setTab('total') }}>Total</span>
        </div>
      </div>
      <div className="stats-elements">
        {loading ? (
          <Loader />
        ) : (
          <>
            <StatsElement
              label={'Profile Views'}
              blocks={[{
                icon: <Visible />,
                text: 'Profile Views',
                value: values.profileViews
              }]}
            />
            <StatsElement
              label={'Waves'}
              blocks={[{
                icon: <Waves />,
                text: 'Received Waves',
                value: values.waves
              }]}
            />
            <StatsElement
              label={'Chats'}
              blocks={[
                {
                  icon: <Inbox />,
                  text: 'Sent Messages',
                  value: values.messagesSend
                },
                {
                  icon: <Outbox />,
                  text: 'Received Messages',
                  value: values.receivedMessages
                }
              ]}
            />
            <StatsElement
              label={'Photos Views'}
              blocks={[
                {
                  icon: <Private />,
                  text: 'Private Photos',
                  value: values.privatePhotos
                },
                {
                  icon: <Photos />,
                  text: 'All Photos',
                  value: values.allPhotos
                }
              ]}
            />
          </>
        )}
      </div>
    </>
  )

}
