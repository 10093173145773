import { useState } from 'react';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { apiUserConfirmCheck, apiUserConfirmCreate, createUserFromResponse } from "../../../actions/userActions";
import { OtpInput } from '../../../components';
import ResendTimer from '../../../components/ResendTimer';
import LogoWithIcon from "../../../icons/LogoWithIcon";
import { userSignInAction } from "../../../reducers/user";
import { sendSmallNotification } from "../../../utils";

export default function CodeConfirm({ phone, onBack, confirmId }: { phone: string, onBack: () => void, confirmId: number }) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [timerActive, setTimerActive] = useState(true);

  const onSubmit = async (code: string) => {

    const response = await apiUserConfirmCheck(confirmId, code)
    if (response.status === 'ok') {
      dispatch(userSignInAction({
        token: response.token,
        user: createUserFromResponse(response.user),
        profiles: response.profiles,
      }));
      navigate('/profile')
    } else {
      sendSmallNotification(dispatch, {
        title: 'Wrong verification code',
        message: 'Try to type it once again or resend the code.',
        type: 'error'
      })
    }

  }

  const onResend = async () => {
    setTimerActive(true);
    apiUserConfirmCreate(phone);
  }

  return (
    <div className="sign-up">
      <div className="sign-up-inner">
        <div className="sign-up-logo-with-icon">
          <LogoWithIcon />
        </div>
        <span className="sign-up-title">Phone Verification</span>
        <span className="sign-up-text">Please enter the 4-digit verification code that was sent to <b>{phone.replaceAll(' ', '')}</b>. The code is valid for 30 minutes.</span>
        <OtpInput onSubmit={code => {
          onSubmit(code)
        }} />
        {timerActive && (
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <ResendTimer seconds={60} onFinish={() => setTimerActive(false)} />
          </div>
        )}
        {!timerActive && (
          <span className="sign-up-resend">Didn't get the code? <a className="sign-up-resend-link" onClick={onResend}>Resend</a></span>
        )}
        <span className="sign-up-back" onClick={onBack}>Back</span>
      </div>
    </div>
  )

}
