import { FC, useEffect, useRef, useState } from "react";

interface ResendTimerI {
  seconds: number;
  onFinish: () => void;
}

const ResendTimer: FC<ResendTimerI> = ({ seconds: intiailSeconds, onFinish }) => {
  const [sec, setSec] = useState(intiailSeconds);
  const interval = useRef<any>(null);

  useEffect(() => {
    if (sec <= 0) {
      onFinish();
      if (interval.current) {
        clearInterval(interval.current);
      }
      return;
    }
    if (!interval.current) {
      interval.current = setInterval(() => {
        setSec((s) => s - 1);
      }, 1000);
    }
  }, [sec]);

  if (sec <= 0) return null;

  return (
    <span>
      {sec}s
    </span>
  )

}

export default ResendTimer