import {PROFILE_TYPE} from "../types/models/profile";
import {DEFAULT_BACKGROUND_COLOR, PROFILE_TYPES} from "../constants";

export const getProfileColor = (type: PROFILE_TYPE | null) => {

  if (type === null) {
    return DEFAULT_BACKGROUND_COLOR
  }

  return PROFILE_TYPES[type].color

}

export const getProfileRgbaColor = (type: PROFILE_TYPE | null) => {

  if (type === null) {
    return DEFAULT_BACKGROUND_COLOR
  }

  return PROFILE_TYPES[type].backgroundColor

}
