import { FC, useEffect, useMemo, useRef, useState } from "react";
import { BeforeInstallPromptEvent } from "react-pwa-add-to-homescreen/lib/esm/interfaces";
import getUserAgentInfo from "../../utils/getUserAgentInfo";
import BasicButton from "../ui/BasicButton/BasicButton";
import { Modal } from "../ui/Modal/Modal";
import './index.scss';
import moment from "moment";
import { PROFILE_TYPES } from "../../constants";
import InstallIcon from "../../icons/InstallIcon";
import Arrow from "../../icons/Arrow";

type InstallType = 'INSTALL_NATIVE' | 'INSTALL_GUIDE';

const { os, browser } = getUserAgentInfo();

interface InstallComponentI {
  onInstallClick: () => void;
  onRemindClick: () => void;
  onDoNotShowClick: () => void
}
const InstallComponent: FC<InstallComponentI> = ({ onInstallClick, onRemindClick, onDoNotShowClick }) => {
  
  const HHH = (
    <>
      <span style={{ color: PROFILE_TYPES['friend'].color }}>Hey.</span>
      <span style={{ color: PROFILE_TYPES['flirt'].color }}>Hey.</span>
      <span style={{ color: PROFILE_TYPES['fun'].color }}>Hey!</span>
    </>
  )
  
  return (
    <div className="install-component">
      <h1>{HHH}</h1>
      <h2>Now you can install this web app on your device!</h2>
      <div className="btn-wrapper install-wrapper">
        <BasicButton
          className="install-btn"
          onClick={onInstallClick}
          title='Install'
        />
      </div>
      <div className="btn-wrapper">
        <BasicButton
          className="decline-btn"
          onClick={onDoNotShowClick}
          title="Don&apos;t show me again"
          variant='outlined'
        />
        <BasicButton
          className="decline-btn"
          onClick={onRemindClick}
          title="Remind me later"
          variant='outlined'
        />
      </div>
    </div>
  )
}


type InstallGuideI = Pick<InstallComponentI, 'onDoNotShowClick' | 'onRemindClick'>;

const InstallGuide: FC<InstallGuideI> = ({ onDoNotShowClick, onRemindClick }) => {
  const isDesktop = os === 'Windows' || os === 'Mac OS';
  const isMobile = os === 'Android' || os === 'iOS';

  return (
    <div className="install-guide-wrapper">
      <div className="install-icon">
        <InstallIcon/>
      </div>
      <h2>App installation guide</h2>
      {isDesktop && (
        <span className="text">
          You need to click on this icon in the top right corner of the window.
        </span>
      )}
      {browser === 'Edge' && isDesktop && (
        <>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/edge/desktop.png"
              alt="guide"
            />
            <Arrow className="arrow arrow-edge"/>
          </div>
        </>
      )}
      {browser === 'Edge' && isMobile && (
        <>
          <span className="guide-item">1. Click burger menu.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/edge/mobile/step1.png"
              alt="guide1"
            />
          </div>
          <span className="guide-item">2. Tap Share.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/edge/mobile/step2.png"
              alt="guide2"
            />
          </div>
          <span className="guide-item">3. Find and tap Add to Home Screen.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/ios/add-home-screen.png"
              alt="guide3"
            />
          </div>
        </>
      )}
      {browser === 'Chrome' && isMobile && (
        <>
          <span className="guide-item">1. In the right corner of the address bar tap Share.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/chrome/mobile/step1.png"
              alt="guide1"
            />
          </div>
          <span className="guide-item">2. Add to Home Screen.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/ios/add-home-screen.png"
              alt="guide2"
            />
          </div>
          <span className="guide-item">3. Confirm or edit the website details.</span>
        </>
      )}
      {browser === 'Chrome' && isDesktop && (
        <>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/chrome/desktop.png"
              alt="guide1"
            />
            <Arrow className="arrow arrow-chrome"/>
          </div>
        </>
      )}
      {browser === 'Safari' && isMobile && (
        <>
          <span className="guide-item">1. In the center of the address bar tap Share.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/safari/mobile/step1.png"
              alt="guide1"
            />
          </div>
          <span className="guide-item">2. Add to Home Screen.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/ios/add-home-screen.png"
              alt="guide2"
            />
          </div>
        </>
      )}
      {browser === 'Firefox' && isMobile && (
        <>
          <span className="guide-item">1. Click burger menu.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/firefox/mobile/step1.png"
              alt="guide1"
            />
          </div>
          <span className="guide-item" style={{ display: 'block' }}>
            2. Tap Share.
          </span>
          <br />
          <span className="guide-item">3. Add to Home Screen.</span>
          <div className="install-img-wrapper">
            <img
              src="/images/install-guide/ios/add-home-screen.png"
              alt="guide2"
            />
          </div>
        </>
      )}
      <div className="btn-wrapper">
        <BasicButton
          className="decline-btn"
          onClick={onDoNotShowClick}
          title="Don&apos;t show me again"
          variant='outlined'
        />
        <BasicButton
          className="decline-btn"
          onClick={onRemindClick}
          title="Remind me later"
          variant='outlined'
        />
      </div>
    </div>
  )
}

const InstallAppModal = () => {
  const [open, setOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [installType, setInstallType] = useState<InstallType>('INSTALL_NATIVE');
  const timeoutId = useRef<any>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
  }, []);

  useEffect(() => {
    setInstallType(deferredPrompt ? 'INSTALL_NATIVE' : 'INSTALL_GUIDE');
    if (timeoutId.current) {
      clearTimeout(timeoutId.current)
    }
    timeoutId.current = setTimeout(() => {
      const isPwaAlreadyInstalled = localStorage.getItem('pwa_already_installed') === 'true';
      const isRemind = moment().isAfter(localStorage.getItem('pwa_install_remind_date') || 0);
      const doNotShow = localStorage.getItem('pwa_install_do_not_show') === 'true';
      const isValidPlatform = os !== 'Other' && browser !== 'Other';
      setOpen(!isPwaAlreadyInstalled && isRemind && isValidPlatform && !doNotShow);
    }, 500)
  }, [deferredPrompt]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        localStorage.setItem('pwa_already_installed', String(choiceResult.outcome === 'accepted'));
        if (choiceResult.outcome === 'dismissed') {
          setRemindLater();
        }
        setOpen(false);
        setDeferredPrompt(null);
      });
    }
  };

  const setRemindLater = () => {
    localStorage.setItem(
      'pwa_install_remind_date',
      moment().add(5, 'days').startOf('day').toISOString()
    );
  }

  const handleDoNotShow = () => {
    localStorage.setItem('pwa_install_do_not_show', 'true');
    setOpen(false);
  }

  const handleRemindClick = () => {
    setRemindLater();
    setOpen(false);
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        setRemindLater();
        setOpen(false);
      }}
      content={(
        <div className="content-wrapper">
          {installType === 'INSTALL_NATIVE' && (
            <InstallComponent
              onInstallClick={handleInstallClick}
              onRemindClick={handleRemindClick}
              onDoNotShowClick={handleDoNotShow}
            />
          )}
          {installType === 'INSTALL_GUIDE' && (
            <InstallGuide
              onRemindClick={handleRemindClick}
              onDoNotShowClick={handleDoNotShow}
            />  
          )}
        </div>
      )}
    />
  )
}

export default InstallAppModal;