import {PROFILE_TYPE} from "../types/models/profile";

interface ProfileTypeData {
  icon: string;
  arrow: string;
  arrowLeft: string;
  label: string;
  color: string;
  backgroundColor: string;
  infoText: string;
  relationships?: {
    values: string[];
    type_id: number;
  };
  birthdateId: number;
}
export const PROFILE_TYPES: Record<PROFILE_TYPE, ProfileTypeData> = {
  friend: {
    icon: '/images/icons/types/friend.svg',
    arrow: '/images/icons/switcher/arrow-up-friend.svg',
    arrowLeft: '/images/icons/switcher/arrow-left-friend.svg',
    label: 'Friend',
    color: 'rgba(0, 98, 196, 1)',
    backgroundColor: 'rgba(235, 246, 255, 1)',
    infoText:
      'account help you to find a gamer buddy, fellow travel enthusiasts and activity partners. New to town? Meet others to help you enjoy your new home.',
    relationships: {
      values: [
        "New Friends",
        "Happy Hour Drinks",
        "Wingmam / Wingwoman",
        "Gym Buddy",
        "Gaming Friends",
        "Concert Buddies",
        "Outdoor Partner",
        "Foodies",
        "Online Friends",
        "Cultural Events",
      ],
      type_id: 408,
    },
    birthdateId: 404,
  },
  flirt: {
    icon: '/images/icons/types/flirt.svg',
    arrow: '/images/icons/switcher/arrow-up-flirt.svg',
    arrowLeft: '/images/icons/switcher/arrow-left-flirt.svg',
    label: 'Flirt',
    color: 'rgba(101, 66, 221, 1)',
    backgroundColor: 'rgba(242, 238, 255, 1)',
    infoText:
      'community is only for when you are looking to make an emotional connection.',
    relationships: {
      values: [
        "Marriage",
        "Long-term relationship",
        "A great date or two",
        "Just dating",
      ],
      type_id: 514,
    },
    birthdateId: 509,
  },
  fun: {
    icon: '/images/icons/types/fun.svg',
    arrow: '/images/icons/switcher/arrow-up-fun.svg',
    arrowLeft: '/images/icons/switcher/arrow-left-fun.svg',
    label: 'Fun',
    color: 'rgba(167, 76, 184, 1)',
    backgroundColor: 'rgba(255, 242, 255, 1)',
    infoText:
      'is an Eggplant and Taco friendly place. No need to chat about your life’s hopes and dreams, save that talk for Flirt.',
    birthdateId: 619,
  },
};

export const PROFILE_TYPES_LIST: PROFILE_TYPE[] = Object.keys(
  PROFILE_TYPES
) as PROFILE_TYPE[];

export const DEFAULT_BACKGROUND_COLOR = 'rgba(244, 241, 255, 1)'
