import React, {useState} from 'react'
import {ProgressLine} from "./ProgressLine";
import {useDispatch, useSelector} from "react-redux";
import {onBoardingCleanAction, onBoardingUpdateAction} from "../../../reducers/onboarding";
import {profileCreateAction} from "../../../reducers/user";
import {useNavigate} from "react-router-dom";
import {PROFILE_TYPES_LIST} from "../../../constants";
import {ProfileType} from "./ProfileType";
import {PROFILE_TYPE} from "../../../types/models/profile";
import {convertToValues} from "../../../utils/convertToValues";
import { ROUTES } from '../../../types/enum';

export function OnBoardingOtherType() {

  const onBoarding = useSelector(state => state.onBoarding)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const profiles = useSelector(state => state.user.profiles)
  const types = profiles.map(profile => profile.type)
  if (onBoarding.profileType) {
    types.push(onBoarding.profileType)
  }

  const [type, setType] = useState<null | PROFILE_TYPE>(null)

  const onBack = () => {
    dispatch(onBoardingUpdateAction({
      values: {
        step: 'about'
      }
    }))
  }

  const submit = () => {
    createProfile()
  }

  const skip = () => {
    createProfile()
  }

  const createProfile = () => {
    dispatch(profileCreateAction({
      profile: {
        name: onBoarding.name,
        publicPhotos: onBoarding.publicPhotos,
        privatePhotos: onBoarding.privatePhotos,
        avatar: onBoarding.avatar?.path,
        adult: onBoarding.avatar?.adult ? +onBoarding.avatar?.adult : 0,
        adult_status: onBoarding.avatar?.adult ? 1 : 0,
        type: onBoarding.profileType,
        values: convertToValues(onBoarding)
      }
    }))
    dispatch(onBoardingCleanAction({
      type: null
    }))
    navigate(ROUTES.PROFILE)
  }


  return (
    <>
      <div className="on-boarding-top">
        <span className="on-boarding-back-link" onClick={onBack}>
          <img src='/images/icons/common/angle-left.svg' className="on-boarding-back-link-icon"/>
          <span className="on-boarding-back-link-label">Back</span>
        </span>
      </div>
      <ProgressLine/>
      <span className="on-boarding-title">Do you want to fill in your other modes?</span>
      <div className="on-boarding-profile-types">
        {PROFILE_TYPES_LIST.filter(_type => types.indexOf(_type) === -1).map((_type) => {
          return (
            <ProfileType type={_type} checked={_type === type} onClick={() => setType(_type)}/>
          )
        })}
      </div>
      <button
        disabled={type === null}
        onClick={submit}
        className="on-boarding-submit-button"
      >
        Continue
      </button>
      <span className="on-boarding-skip-link" onClick={skip}>Skip</span>
    </>
  )
}
